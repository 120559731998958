<template>
  <div>
    <v-data-table id="content"
      :headers="headers"
      :items="userMaterials"
      :items-per-page="5"
      :search="search"
      sort-by="materialName"
      :loading="loading" 
      :loading-text="$t('user.materials.loadingWait')"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title><h2>{{ title }}</h2></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('user.materials.search')"
            single-line
            hide-details
            style="max-width: 300px;"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            color="primary"
            :disabled="loading"
            @click="showSettings()"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
          <v-menu v-if="userLinks.length > 0" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                color="primary"
                :disabled="loading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-text-account</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(itm, i) in userLinks"
                :key="`${i}-userLink-menu`"
                @click="showUserLink(itm)"
              >
                <v-list-item-title>{{ itm.linkName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <DataTableSettings 
            :items="dataTableItems"
            @updateSettings="updateSettings($event)" 
            :key="dataTableSettingsKey" />

          <v-dialog 
            v-model="dialog" 
            persistent 
            :fullscreen="$vuetify.breakpoint.smAndDown" 
            max-width="520px">
            <MaterialAjustments 
              :item="editedItem" 
              :scales="scales"
              @cancel="materialAjustmentsCancel"
              @materialUpdated="materialUpdated" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.materialName="{ item }">
        <span class="primary--text saw-calc-link"
          @click="(openSawCalc(item))">
          {{ item.materialName }}
        </span>
      </template>
    <template v-slot:item.heavyScaleForged="{ item }">
      <v-icon v-show="item.heavyScaleForged">
        mdi-check
      </v-icon>
    </template>
    <template v-slot:item.htQuenched="{ item }">
      <v-icon v-show="item.htQuenched">
        mdi-check
      </v-icon>
    </template>
      <template v-slot:item.details="{ item }">
        <MaterialDetails :item="item" class="mt-1 mb-3" />
      </template>
      <template v-slot:item.copy="{ item }">
        <v-icon
          class="mr-2"
          :disabled="userLink == null"
          @click="copyItem(item)"
        >
          mdi-content-save
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          :disabled="userLink != null"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          :disabled="userLink != null"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <span>{{ $t('user.materials.noMaterialsFound') }}</span>
      </template>
    </v-data-table>

    <v-overlay :value="itemOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableSettings from "@/components/common/DataTableSettings";
import MaterialDetails from "@/components/public/SawCalc/Selectors/Material/MaterialDetails";
import MaterialAjustments from "@/components/user/Material/MaterialAjustments";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "MaterialsUser",

  components: {
    DataTableSettings,
    MaterialDetails,
    MaterialAjustments,
  },

  data() {
    return {
      headersList: [
        {
          text: this.$t("user.materials.materialNameLabel"),
          value: "materialName",
        },
        {
          text: this.$t("user.materials.hardnessLabel"),
          value: "hardnessDescription",
        },
        {
          text: this.$t("user.materials.surfaceConditionLabel"),
          value: "heavyScaleForged",
        },
        {
          text: this.$t("user.materials.materialConditionLabel"),
          value: "htQuenched",
        },
        {
          text: this.$t("user.materials.detailsLabel"),
          value: "details",
        },
        {
          text: this.$t("user.materials.saveButton"),
          value: "copy",
        },
        {
          text: this.$t("user.materials.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {
        materialID: 0,
      },
      defaultItem: {
        materialID: 0,
      },
      loading: false,
      dataTableItems: [],
      itemOverlay: false,
      userLink: null,
    };
  },

  computed: {
    ...mapGetters([
      "userMaterials",
      "materialsUserSettings",
      "scales",
      "user",
      "dataTableSettingsKey",
    ]),
    title() {
      if (this.userLink) {
        return this.userLink.linkName;
      } else {
        return this.$t("user.materials.title");
      }
    },
    userLinks() {
      if (this.user) {
        const userLinks = [...this.user.userLinks];
        userLinks.unshift({
          id: 0,
          userID: this.user.userID,
          linkName: this.$t("user.materials.myMaterials"),
        });

        return userLinks;
      } else {
        return [];
      }
    },
  },

  methods: {
    getUserMaterials(showNotification) {
      this.loading = true;

      let userID = null;
      if (this.userLink) {
        userID = this.userLink.userID;
      }

      this.$store
        .dispatch("getUserMaterials", {
          userID: userID,
          showNotification: showNotification,
        })
        .then(
          (response) => {
            this.loading = false;

            if (showNotification) {
              const notification = {
                show: true,
                result: true,
                message: response.message,
              };

              this.$store.dispatch("showSnackbar", notification);
            }

            this.updateHeaders();

            let imagesList = this.userMaterials
              .filter((item) => item.imageID != null && item.imageID > 0)
              .map((o) => ({
                userMaterialID: o.userMaterialID,
                fileName: o.fileName,
              }));

            if (imagesList.length > 0) {
              this.$store.dispatch("getUserMaterialImages", {
                list: imagesList,
              });
            }
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function () {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveMaterialsUserSettings", params);

      this.updateHeadersFromSettings(params.headers);
    },
    updateHeaders() {
      if (this.settings()) {
        this.updateHeadersFromSettings(this.materialsUserSettings.headers);
      } else {
        this.headers = [];
        this.headers = [...this.headersList];

        if (this.userLink == null) {
          const copy = this.headers.find(item => item.value == 'copy')

          if (copy){
            const index = this.headers.indexOf(copy);

            if (index > -1) {
              this.headers.splice(index, 1);
            }
          }
        }
      }
    },
    updateHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.dataTableItems = this.materialsUserSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text,
          value: o.value,
          selected: true,
        }));

        items.sort(function (a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadMaterialsUserSettingsComponent");
    },
    settings() {
      return (
        this.materialsUserSettings && this.materialsUserSettings.headers != null
      );
    },
    editItem(item) {
      this.editedIndex = this.userMaterials.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    deleteItem(item) {
      const action = this.$t("user.materials.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("removeUserMaterial", item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.materialsUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    materialsUpdated() {
      this.dialog = false;
      this.getUserMaterials(false);
    },
    openSawCalc(item) {
      this.$store.dispatch("setSavedInputMaterial", item).then(() => {
        router.push({ name: "SawCalc" }).catch(() => {});
      });
    },
    materialAjustmentsCancel() {
      this.dialog = false;
    },
    materialUpdated() {
      this.dialog = false;

      this.itemOverlay = true;

      this.$store.dispatch("updateUserMaterial", this.editedItem).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.materialsUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    showUserLink(item) {
      if (item && item.id != 0) {
        this.userLink = item;
      } else {
        this.userLink = null;
      }

      this.getUserMaterials(true);
    },
    copyItem(item){
      this.$store.dispatch("copyUserMaterial", { userMaterialID: item.userMaterialID }).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.getUserMaterials(true);
  },
};
</script>

<style lang="scss" scoped>
.image-controls {
  position: relative;
  left: 5px;
  top: 7px;
  height: 0px;
}
.image-controls-buttons {
  color: map-get($theme, footer-link-color);
}
.saw-calc-link {
  cursor: pointer;
}
</style>