<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="itemOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>

              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('user.materials.materialNameLabel')"
                  :disabled="itemOverlay"
                  v-model="item.materialName"
                  :rules="inputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8">
                <v-card flat>
                  <v-card-title class="pa-0 pb-2">
                    <span class="card-text-caption">{{
                      $t("sawCalc.inputMaterial.scaleLabel")
                    }}</span>
                  </v-card-title>
                  <v-list dense class="pa-0 pb-2">
                    <v-list-item-group
                      v-model="scaleIndex"
                      color="primary"
                      class="pr-2"
                    >
                      <v-list-item
                        v-for="(item, i) in scales"
                        :key="i"
                        :disabled="itemOverlay"
                        @click="setScale(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="py-1"
                :class="{ 'py-0': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('sawCalc.inputMaterial.hardnessLabel')"
                  v-model="item.hardness"
                  :rules="inputRules"
                  :disabled="itemOverlay"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-3">
              <v-col cols="12" sm="12" class="pa-0">
                <div class="card-text-caption">
                  {{ $t("sawCalc.inputMaterial.surfaceConditionLabel") }}
                </div>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-switch
                  class="pa-0"
                  v-model="item.heavyScaleForged"
                  :label="$t('sawCalc.inputMaterial.heavyScaleForgedLabel')"
                  :disabled="itemOverlay"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="pl-3">
              <v-col cols="12" sm="12" class="pa-0">
                <div class="card-text-caption">
                  {{ $t("sawCalc.inputMaterial.materialConditionLabel") }}
                </div>
              </v-col>
              <v-col cols="12" sm="12" class="pa-0">
                <v-switch
                  class="pa-0"
                  v-model="item.htQuenched"
                  :label="$t('sawCalc.inputMaterial.htQuenchedLabel')"
                  :disabled="itemOverlay"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('user.materials.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="itemOverlay"
                  @click="cancel()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('user.materials.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="saveMaterial(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserMaterialAjustments",

  props: {
    scales: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      scaleIndex: null,
      itemOverlay: false,
      inputRules: [
        (v) =>
          (!!v && this.validHardness) ||
          this.$t("sawCalc.inputMaterial.inputRulesNumber"),
      ],
    };
  },

  computed: {
    ...mapGetters(["axiosSource"]),
    valid() {
      if (
        !this.item ||
        this.item.userMaterialID == undefined ||
        this.item.userMaterialID == 0 ||
        this.item.materialName == undefined ||
        this.item.materialName == "" ||
        this.item.hardness == undefined ||
        this.item.hardness == "" ||
        this.item.hardness == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    validHardness() {
      return this.item && parseInt(this.item.hardness) >= 0;
    },
    saveDisabled() {
      if (!this.valid || this.itemOverlay) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    setScaleIndex() {
      let scaleIndex = null;

      if (this.item && this.item.scale) {
        scaleIndex = this.item.scale.scaleID - 1;
      }

      this.scaleIndex = scaleIndex;
    },
    setScale(item) {
      if (item.id == this.item.scale.scaleID) {
        return;
      }

      this.item.scale = item;

      this.$store
        .dispatch("getHardness", {
          scale: item,
          hardness: this.item.hardness,
          hardnessCalculated: this.item.hardnessCalculated,
        })
        .then((response) => {
          this.item.hardness = response.hardness;
          this.item.hardnessCalculated = response.hardnessCalculated;
        });
    },
    saveMaterial() {
      this.$emit("materialUpdated");
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },

  mounted() {
    this.setScaleIndex();
  },
};
</script>

<style lang="scss" scoped>
.v-list .v-list-item--active {
  border-left: 3px solid map-get($theme, secondary-color);
}
.v-input--selection-controls {
  margin-top: 5px;
}
</style>
